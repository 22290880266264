import YoutubeEmbed from "./YoutubeEmbed";
import styles from "../styles/videoPage.module.scss";
import { Helmet } from "react-helmet";

const videoIds = [
	{ id: "ZxC7QgPx7WY", name: "Plastic" },
	{ id: "TQeZtDGAgV8", name: "New Skin" },
	{ id: "TeO_VZqWjzE", name: "Dupes" },
];

const Video = () => {
	return (
		<div className={styles.videoPage}>
			<Helmet>
				<title>Faith Kiddo | Video</title>
			</Helmet>
			{videoIds.map((video, index) => {
				return (
					<div key={video.id} className={styles.videoWrapper}>
						<YoutubeEmbed videoId={video.id} width="100%" height="100%" autoplay={index === 0} ></YoutubeEmbed>
						<div className={styles.videoDetails}>{video.name}</div>
					</div>
				);
			})}
		</div>
	);
};

export default Video;
