import SocialLink from "./SocialLink";
import "../styles/socialLinks.scss";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import applemusicLogo from "../images/applemusic.svg";
import amazonLogo from "../images/amazon.png";

function SocialLinks() {
	return (
		<div className="socialLinks">
			<ul>
				<li className="spotify">
					<SocialLink
						href="https://open.spotify.com/artist/1TPRjP7YmNSC7ddLwibvXW?si=CHrCqe0ZSDSsVVY-zvWJOQ"
						icon={faSpotify}
					></SocialLink>
				</li>
				<li className="instagram">
					<SocialLink
						href="https://www.instagram.com/thefaithkiddo/"
						icon={faInstagram}
					></SocialLink>
				</li>
				<li className="facebook">
					<SocialLink
						href="https://www.facebook.com/FedericaKiddo/"
						icon={faFacebook}
					></SocialLink>
				</li>
				<li className="youtube">
					<SocialLink
						href="https://www.youtube.com/channel/UCJfWJyyic1znsynQIQkf7Ig"
						icon={faYoutube}
					></SocialLink>
				</li>
				<li className="appleMusic">
					<SocialLink
						href="https://music.apple.com/it/artist/faith-kiddo/1524480648"
						img={applemusicLogo}
						socialName="Apple Music"
					></SocialLink>
				</li>
				<li className="amazonMusic">
					<SocialLink
						href="https://music.amazon.it/artists/B08DDFWWFS/faith-kiddo"
						img={amazonLogo}
						socialName="Amazon Music"
					></SocialLink>
				</li>
			</ul>
		</div>
	);
}

export default SocialLinks;
