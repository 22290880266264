import React, { useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./components/Home";
import Video from "./components/Video";
import Press from "./components/Press";
import CookieConsent from "react-cookie-consent";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cookies from "universal-cookie";
import Music from "./components/Music";
import "./styles/main.scss";

let GAinit = false;

const App = () => {
	const location = useLocation();

	const initGA = () => {
		ReactGA.initialize("G-41R32F0L6G");
		ReactGA.set({ anonymizeIp: true });
		ReactGA.set({ page: window.location.pathname }); // Update the user's current page
		ReactGA.send("pageview");
		console.log("GA loaded");
		GAinit = true;
	};

	useEffect(() => {
		const cookies = new Cookies();
		const cookieConsent = cookies.get("CookieConsent");

		if (cookieConsent && !GAinit) {
			initGA();
		}
	}, [location]);

	return (
		<div className="App">
			<Header />
			<main className={location.pathname.substr(1, location.pathname.length - 1)}>
				<div className="mainContent">
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route path="/music" element={<Music />} />
						<Route path="/press" element={<Press />} />
						<Route path="/video" element={<Video />} />
					</Routes>
				</div>
			</main>
			<Footer></Footer>
			<CookieConsent
				location="bottom"
				containerClasses="cookie-layer"
				// enableDeclineButton
				buttonText="ACCETTA"
				declineButtonText="RIFIUTA"
				style={{ background: "#c23a96", fontSize: "14px" }}
				buttonStyle={{ color: "#fff", border: "solid  2px white", background: "#c23a96", fontSize: "14px", fontWeight: "bold", margin: "15px" }}
				declineButtonStyle={{ color: "#fff", border: "solid  2px white", background: "#c23a96", fontSize: "14px", fontWeight: "bold", margin: "15px 5px" }}
				onAccept={() => {
					initGA();
				}}
			>
				Questo sito usa cookie, anche di terze parti, per offrirti una miglior esperienza di navigazione ed analizzare il traffico.
			</CookieConsent>
		</div>
	);
};

export default App;
