import Name from "./Name";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import styles from "../styles/header.module.scss";

const Header = () => {
	return (
		<header className={styles.header}>
			<div>
				<Link to="/">
					<Name></Name>
				</Link>
				<Nav></Nav>
			</div>
		</header>
	);
};

export default Header;
