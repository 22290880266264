import styles from "../styles/article.module.scss";

const Article = (props) => {
	return (
		<article className={`${styles.articleWrapper}`}>
			<a href={props.link} target="_blank" rel="noreferrer">
				<div className={styles.imgWrapper}>
					<img src={props.img} alt={props.alt} loading="lazy"></img>
				</div>
				<div className={styles.details}>
					<span className={styles.title}>{props.title}</span>
					{props.date && <span className={styles.date}>{new Date(props.date).toLocaleDateString('it', {dateStyle: 'medium'})}</span>}
				</div>
			</a>
		</article>
	);
};

export default Article;
