import copertinaEP from "../images/copertinaEP.jpeg";

function Image() {
	return (
		<div className="image">
			<div className="imgWrapper">
				<img src={copertinaEP} alt="Faith Kiddo - Venom with Glitter" />
			</div>
		</div>
	);
}

export default Image;
