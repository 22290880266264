import Image from "./Image";
import Spotify from "./Spotify";
import SocialLinks from "./SocialLinks";
import logo from "../images/KiddoLogo.png";

const TopPage = () => {
	return (
		<section className="top-page">
			<Image />
			<aside>
				<div className="logoWrapper">
					<img src={logo} alt="Faith Kiddo" />
				</div>
				<div className="title">Venom with Glitter</div>
				<div className="outNow">OUT NOW</div>
				<div className="listen">ASCOLTA ORA</div>
				<SocialLinks />
				<div className="spotifyEmbed">
					<Spotify height="380" src="https://open.spotify.com/embed/album/1euXFJm2Uic2edDwZUyEJY?utm_source=generator" />
				</div>
				<Spotify height="160" src="https://open.spotify.com/embed/album/5CUcCZ74tpZJiZlXf4mDJ9?utm_source=generator&theme=0" />
			</aside>
		</section>
	);
};

export default TopPage;
