import React from "react";

const YoutubeEmbed = (props) => {
	const autoplay = props.autoplay ? "?autoplay=1&mute=1" : "";

	return (
		<iframe
			width={props.width}
			height={props.height}
			src={`https://www.youtube.com/embed/${props.videoId}${autoplay}`}
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		></iframe>
	);
};

export default YoutubeEmbed;
