import { NavLink } from "react-router-dom";
import styles from '../styles/nav.module.scss';

const Nav = () => {
	return (
		<nav className={styles.nav}>
			<ul>
				<li>
					<NavLink className={(navData) => (navData.isActive ? styles.current : '')} to="/music">MUSIC</NavLink>
				</li>
				<li>
					<NavLink className={(navData) => (navData.isActive ? styles.current : '')} to="/video">VIDEO</NavLink>
				</li>
				<li>
					<NavLink className={(navData) => (navData.isActive ? styles.current : '')} to="/press">
						PRESS
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default Nav;
