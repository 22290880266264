import React from "react";
const Spotify = (props) => {
	return (
		<iframe
			src={props.src}
			width="100%"
			height={props.height}
			frameBorder="0"
			allowFullScreen=""
			allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
			title="Spotify player"
		></iframe>
	);
};

export default Spotify;
