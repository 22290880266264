import { useLocation } from "react-router-dom";
import "../styles/footer.scss";
import SocialLinks from "./SocialLinks";

const Footer = () => {
	const location = useLocation();

	return (
		<footer>
			{location.pathname !== "/" && <SocialLinks />}
			<div className="copyright">
				<span>&copy; {new Date().getFullYear()} Faith Kiddo</span>
				<span>All rights reserved</span>
				<span>Proudly made by Giulia Zatti</span>
			</div>
		</footer>
	);
};

export default Footer;
