import Spotify from "./Spotify";
import { Helmet } from "react-helmet";

const Music = () => {
	return (
		<div style={{ maxWidth: "800px", width: "100%", margin: "auto" }}>
			<Helmet>
				<title>Faith Kiddo | Music</title>
			</Helmet>
			<Spotify height="160" src="https://open.spotify.com/embed/album/5CUcCZ74tpZJiZlXf4mDJ9?utm_source=generator&theme=0"/>
			<Spotify height="630" src="https://open.spotify.com/embed/artist/1TPRjP7YmNSC7ddLwibvXW?utm_source=generator" />
		</div>
	);
};

export default Music;
