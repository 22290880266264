import skytg24 from "../images/press/skytg24.png";
import skytg24bis from "../images/press/skytg24-2.png";
import insidemusic from "../images/press/insidemusic.png";
import tuttorock from "../images/press/tuttorock.png";
import endofacentury from "../images/press/endofacentury.png";
import endofacentury2 from "../images/press/endofacentury2.png";
import radio5punto9 from "../images/press/radio5punto9.png";
import brotsmagazine from "../images/press/brotsmagazine.png";
import thesoundcheck from "../images/press/thesoundcheck.png";
import thesoundcheck2 from "../images/press/thesoundcheck2.png";
import headtopics from "../images/press/headtopics.png";
import stefanofisico from "../images/press/stefanofisico.png";
import goccespettacolo from "../images/press/goccespettacolo.png";
import allmusic from "../images/press/allmusic.png";
import globalstorytelling from "../images/press/globalstorytelling.png";
import radiondadurto from "../images/press/radioondaurto.png";
import gazzettamn from "../images/press/gazzettamn.png";
import styles from "../styles/press.module.scss";
import Article from "./Article";
import { Helmet } from "react-helmet";
import YoutubeEmbed from "./YoutubeEmbed";

const articles = [
	{
		link: "https://tg24.sky.it/spettacolo/musica/2022/04/21/faith-kiddo-venom-with-glitter",
		alt: "Sky TG24 | Faith Kiddo, Venom with Glitter mostra i quattro volti della stessa bambola",
		img: skytg24,
		title: "Sky TG24",
		name: "skytg",
		date: "2022-04-21",
	},
	{
		link: "https://tg24.sky.it/spettacolo/musica/2022/05/15/faith-kiddo-venom-with-glitter",
		alt: "Faith Kiddo, il suo EP, dal titolo Venom with Glitter, cambia pelle e morde la vita",
		img: skytg24bis,
		title: "Sky TG24",
		name: "skytg2",
		date: "2022-05-15",
	},
	{
		link: "https://www.insidemusic.it/intervista-faith-kiddo/",
		alt: "insidemusic.it | Intervista a Federica alias Faith Kiddo: MTV, sarcasmo e un po’ di veleno",
		img: insidemusic,
		title: "insidemusic.it",
		name: "insidemusic",
		date: "2022-04-21",
	},
	{
		link: "https://www.tuttorock.com/news/faith-kiddo-new-skin-tra-veleno-ironia-e-sarcasmo/",
		alt: "FAITH KIDDO – “NEW SKIN” TRA VELENO, IRONIA E SARCASMO",
		img: tuttorock,
		title: "TuttoRock",
		name: "tuttorock",
		date: "2022-05-08",
	},
	{
		link: "https://endofacentury.it/2022/04/05/faith-kiddo-intervista-aspettatevi-psicopatia-sensualita-e-un-pizzico-di-rammarico/amp/?fbclid=IwAR087SnPd1JDxT8-LKFvu9ornTcftJh49YiUoiqEbTmhf7X0WNxTbkhSLv4",
		alt: "Faith Kiddo: “Aspettatevi Psicopatia, Sensualità E Un Pizzico Di Rammarico”",
		img: endofacentury,
		title: "End Of a Century",
		name: "endofacentury",
		date: "2022-04-05",
	},
	{
		link: "https://endofacentury.it/2022/04/21/faith-kiddo-dal-29-aprile-il-primo-ep-venom-with-glitter-track-by-track/amp/",
		alt: "Faith Kiddo, dal 29 aprile il primo EP “Venom With Glitter",
		img: endofacentury2,
		title: "End Of a Century",
		name: "endofacentury2",
		date: "2022-04-21",
	},
	{
		link: "https://www.radio5punto9.it/new-skin-al-debutto-il-nuovo-singolo-della-cantante-mantovana-faith-kiddo/",
		alt: "“NEW SKIN”, AL DEBUTTO IL NUOVO SINGOLO DELLA CANTANTE MANTOVANA FAITH KIDDO",
		img: radio5punto9,
		title: "Radio 5 Punto 9",
		name: "radio5punto9",
		date: "2022-04-06",
	},
	{
		link: "https://www.brots.cloud/blog-post/faith-kiddo-new-skin",
		alt: 'Faith Kiddo il nuovo singolo "New Skin"',
		img: brotsmagazine,
		title: "Brots Magazine",
		name: "brotsmagazine",
		// no date here
	},
	{
		link: "https://thesoundcheck.it/2022/04/15/faith-kiddo-lintervista-in-attesa-del-suo-ep-venom-with-glitter/",
		alt: "Faith Kiddo: l’intervista in attesa del suo EP “Venom With Glitter”",
		img: thesoundcheck,
		title: "The Soundcheck",
		name: "thesoundcheck",
		date: "2022-04-15",
	},
	{
		link: "https://headtopics.com/it/faith-kiddo-venom-with-glitter-sono-i-4-volti-della-stessa-bambola-25799958",
		alt: "Faith Kiddo, Venom with Glitter sono i 4 volti della stessa bambola",
		img: headtopics,
		title: "Head Topics",
		name: "headtopics",
		date: "2022-04-21",
	},
	{
		link: "https://stefanofisico.it/2022/04/29/faith-kiddo-vorrei-vivere-sempre-come-kiddo-senza-inibizioni/",
		alt: "Faith Kiddo: “Vorrei vivere sempre come Kiddo, senza inibizioni”",
		img: stefanofisico,
		title: "Stefano Fisico",
		name: "stefanofisico",
		date: "2022-04-29",
	},
	{
		link: "https://goccedispettacolo.it/faith-kiddo-presenta-lep-venom-with-glitter-fuori-il-29-aprile/",
		alt: "FAITH KIDDO presenta l’EP VENOM WITH GLITTER, fuori il 29 aprile",
		img: goccespettacolo,
		title: "Gocce di Spettacolo",
		name: "goccespettacolo",
		date: "2022-04-27",
	},
	{
		link: "https://www.allmusicitalia.it/news/faith-kiddo-venom-with-glitter.html",
		alt: "FAITH KIDDO L’EP DI DEBUTTO È VENOM WITH GLITTER",
		img: allmusic,
		title: "All Music Italia",
		name: "allmusic",
		date: "2022-05-10",
	},
	{
		link: "https://www.globalstorytelling.it/article/intervista-a-federica-baraldi-e-al-suo-avatar-faith-kiddo-mi-ha-salvato-la-vita",
		alt: 'Intervista a Federica Baraldi e al suo avatar: "Faith Kiddo mi ha salvato la vita"',
		img: globalstorytelling,
		title: "Global Story Telling",
		name: "globalstorytelling",
		date: "2022-05-26",
	},
	{
		link: "https://rebelgirl.radiondadurto.org/2022/05/30/faith-kiddo-venom-with-glitter/",
		alt: "FAITH KIDDO – VENOM WITH GLITTER",
		img: radiondadurto,
		title: "Radio Onda d'Urto",
		name: "radioondaurto",
		date: "2022-05-30",
		col: 2,
	},
	{
		link: "https://thesoundcheck.it/2022/06/17/faith-kiddo-venom-with-glitter-intervista/",
		alt: 'Faith Kiddo: «"Venom With Glitter" sono le due facce della mia medaglia»',
		img: thesoundcheck2,
		title: "The Soundcheck",
		name: "thesoundcheck2",
		date: "2022-06-17",
	},
	{
		link: "https://gazzettadimantova.gelocal.it/tempo-libero/2020/12/09/news/faith-kiddo-quando-la-violenza-subita-diventa-musica-1.39639992",
		alt: "Faith Kiddo, quando la violenza subita diventa musica",
		img: gazzettamn,
		title: "Gazzetta di Mantova",
		name: "gazzettamn",
		date: "2020-12-09",
	},
	// + video
];

const Press = () => {
	return (
		<div className={styles.pressPage}>
			<Helmet>
				<title>Faith Kiddo | Press</title>
			</Helmet>
			<div className={styles.pressGrid}>
				<div>
					{articles.map((article, index) => {
						let col = article.col ? article.col - 1 : index;
						return col % 3 === 0 ? (
							<Article key={article.name} link={article.link} alt={article.alt} img={article.img} title={article.title} name={article.name} date={article.date} />
						) : (
							""
						);
					})}
				</div>
				<div>
					{articles.map((article, index) => {
						let col = article.col ? article.col - 1 : index;
						return col % 3 === 1 ? (
							<Article key={article.name} link={article.link} alt={article.alt} img={article.img} title={article.title} name={article.name} date={article.date} />
						) : (
							""
						);
					})}
				</div>
				<div>
					{articles.map((article, index) => {
						let col = article.col ? article.col - 1 : index;
						return col % 3 === 2 ? (
							<Article key={article.name} link={article.link} alt={article.alt} img={article.img} title={article.title} name={article.name} date={article.date} />
						) : (
							""
						);
					})}
				</div>
			</div>
			<div className={styles.video}>
				<YoutubeEmbed videoId="mR6hGlUcCNU" width="100%" />
			</div>
			<div className={styles.info}>
				Rassegna stampa a cura di{" "}
				<a href="https://www.instagram.com/liltere/" target="_blank" rel="noreferrer">
					Teresa M. Brancia
				</a>
			</div>
		</div>
	);
};

export default Press;
